@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

    body {
        @apply font-body bg-[#E1E9EB] min-h-screen;
    }

    thead tr {
        @apply bg-gradient-to-tl from-[#0F2033] to-[#0F2033] font-medium text-white;
    }

    thead td {
        @apply py-4;
    }

    td, th {
        @apply px-4 py-2 text-left text-black;
    }
    
    /* tr:nth-child(even) {
        @apply bg-gray-100;
    }     */

    h1 {
        @apply text-xl font-bold;
    }    
}

@layer components {

    .arrow, .icon {
        @apply h-5 w-5 text-[#21859F] rotate-0 transition-all;
    }
    .table-icon {
        @apply h-5 w-5 text-[#00C4CC] scale-150;
    }
    .nav-visible {
        @apply fixed top-0 left-0 h-screen w-[300px] overflow-auto
        bg-white dark:bg-gray-900 shadow-xl transition-all opacity-100 z-10;
    }
    
    /* .nav-hidden {
        @apply  fixed top-0 left-0 h-screen 
        bg-white dark:bg-gray-900 shadow-xl transition-all opacity-100 z-10 ;
    } */

    .navbar-item {
        @apply font-semibold tracking-wide text-base text-[#16596B] w-full text-left block px-4 py-2 rounded-md flex justify-between items-center peer-checked:show-menu;
    }

    .navbar-item > div {
        @apply flex gap-2 items-center;
    }

    .navbar-item.active {
         @apply text-white bg-[#193453];
    }
    .navbar-item.active .icon {
        @apply text-white bg-[#193453];
    }

    .navbar-item:not(.active):hover, .navbar-folder-toggle:hover + .navbar-item {
        @apply bg-brand-primary-100;
    }

    .navbar-folder {
        @apply relative overflow-hidden text-brand-primary-700;
    }

    .navbar-folder-toggle {
        @apply absolute top-0 inset-x-0 w-full h-10 opacity-0 z-10 cursor-pointer;
    }

    .navbar-folder-target {
        @apply overflow-hidden transition-all duration-200 peer-checked:max-h-full max-h-0 ml-7;
    }

    .navbar-folder-target .navbar-item {
        @apply font-normal tracking-normal;
    }

    .show-menu .arrow {
        @apply rotate-180;
    }   

   

    .button-primary, .button-secondary {
        @apply px-6 py-2 text-sm font-medium rounded ease-out duration-200;
    }

    .button-primary {
        @apply bg-white mix-blend-multiply text-black border-solid border-2 border-gray-300 rounded-full;
    }

    .button-secondary {
        @apply button-primary border-none;
    }

    .button-primary:hover, .button-secondary:hover {
        @apply bg-gradient-to-tr from-[#EBF8F9] to-white via-white border-solid border-brand-primary-300;
    }

    .button-confirmation {
        @apply border-2 border-[#16596B] rounded-md w-40 px-2 py-2;
    }

    .button-warn {
        @apply bg-red-100 border-red-300 text-red-900 font-bold py-1.5 px-3.5 border-solid border-2 rounded-full;
    }

    .button-warn:hover {
        @apply bg-red-300 border-red-400 text-black font-bold px-3.5;
    }

    .button-table {
        @apply border-solid border-2 rounded-md border-[#93C1CD] text-[#16596B] h-10
    }

    .button-table:hover {
        @apply bg-blue-100
    }

    .page {
        @apply  transition-all relative;
        min-height: calc(100vh - 64px);
    }

    .nav-hidden + .page {
        @apply ml-[100px] ;
    }

    .page-section {
        @apply overflow-hidden bg-white rounded-xl shadow-lg;
    }

    .page-title {
        @apply px-10 py-5 flex flex-wrap items-center justify-between;
    }


    .filters-container {
        @apply relative flex flex-row justify-between bg-white shadow-xl p-2 rounded-lg mt-[16px];
    }
    .stats-container {
        @apply flex flex-row gap-3 ;
    }
    .stats-card {
        @apply p-3 rounded-lg m-2 ml-0  w-fit bg-white shadow-xl flex justify-between gap-3;
    }

    .stats-card-name { 
        @apply font-bold flex flex-col justify-center;
    }
    .stats-card-number { 
        @apply text-primary-light text-4xl font-medium;
    }


}