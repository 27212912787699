

.header {
    @apply fixed w-full px-5 bg-[#102034] h-[52px] p-2 mb-4 z-[100];

}

.header .user-status-container {
    @apply font-bold text-white absolute right-0 top-0 pt-4 pr-4;
}

.header .error-message {
    @apply text-red-500 text-xl font-bold;
}

.navigation {
    @apply flex flex-col gap-6 px-4 py-6 text-center fixed left-0
        bg-white dark:bg-gray-900 shadow-xl transition-all opacity-100 top-[52px] bottom-0 z-[10000000];
}

.footer {
    @apply ml-[100px];
}

.bubble-trigger {
    z-index: 999;
    cursor: pointer;
}

.bubble-trigger:hover~.price_breaks {
    display: flex;
    flex-direction: column;
    z-index: 9999;
    top: 30px;

}

.price_breaks {
    display: none;
    position: absolute;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 0px 10px 5px rgba(150, 150, 150, 0.3);
    min-width: max-content;
    right: 0;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    top: -8px;
    position: absolute;
    right: 6px;
}

.arrow-up-left {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    top: -8px;
    position: absolute;
    left: 4px;
}

.arrow-left-up-side {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid white;
    left: -10px;
    position: absolute;
    top: 6px;
}

.quantity {
    position: relative;
}

.quantity:hover .only-button {
    display: inline;
}

.only-button {
    display: none;
    position: absolute;
    padding: 0 5px 0 5px;
    border: 1px solid black;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    right: 0;
    z-index: 9999999;
    background-color: white;
}

.only-button:hover {
    background-color: black;
    color: white;
}


tr.selected {
    @apply bg-sky-100;
}

.action-link {
    @apply flex flex-row gap-1 cursor-pointer flex-nowrap text-blueish hover:opacity-80;
}
.action-link .action-link-label {
    @apply text-sm font-medium whitespace-nowrap;
}

.follower-container {
    @apply w-full relative;
}
.follower-container .action-link {
    @apply absolute;
}

.info-popup .info-popup-content table {
    @apply w-full bg-white;
}


.component-checkboxDropdownList-input {
    @apply border border-light-blueish w-full;
}
.domain.account-insights .component-checkboxDropdownList-input {
   @apply border-2 border-zinc-500;
}
.domain.account-insights .geographic-filters .component-checkboxDropdownList-input {
    @apply mt-0;
 }
 


.domain.account-insights table tr,
.domain.account-insights table th {
    @apply border-0;
}

.domain.account-insights table td {
    @apply border-0;
    @apply border-b-2;
}

.domain.account-insights table thead tr {
    background-image: unset;
    @apply bg-white;
}

.domain.account-insights table thead tr th {
    @apply text-black;
    @apply font-bold;
}

.domain.account-insights table thead tr th div {
    @apply justify-start gap-1;
}


.domain.account-insights .business-table tr.selected-row {
    @apply bg-sky-100;
}

.domain.lead-gen .unlock-email {
    & > .icon {
        @apply inline-block;
    }

    .info-popup {
        @apply inline-block relative top-[5px];
    }
}

.domain.alert-item table thead tr {
    background-image: unset;
    @apply bg-[#F8F8F8];
}
.domain.alert-item table thead tr th {
    @apply text-black;
    @apply font-bold;
    @apply border-[#E5E7EB];
}

.map-wrapper-div {
    width: 100%;
    height: 100%;
}

.leaflet-container {
    width: 100%;
    height: 100%;
}

.company-popup h3 {
    font-size: 1.4em;
    margin-bottom: .4em;
}

.company-popup ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

.company-popup li {
    margin-bottom: .4em;
}

.company-popup li:last-child {
    margin-bottom: 0;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    color: white;
    background-color: blueviolet;
    border-radius: 0;
}

.leaflet-popup-content-wrapper a {
    color: cyan;
}

.leaflet-div-icon {
    background: none;
    border: none;
}

.location-marker {
    box-sizing: content-box;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightblue;
    opacity: 0.6;
    font-size: 1rem;
    width: 100%;
    height: 100%;
    border: 1px solid blue;
    border-radius: 100%;
}

.leaflet-control-attribution {
    display: none;
}

/* Channel Manager styles */
.filters-items {
    /* max-width: 1200px; */

    grid-template-columns: 1fr 1fr 1fr 1fr;
    @apply grid px-5 py-2 gap-x-4 gap-y-2 relative;
}
.filters-items.part-compare-items{
    grid-template-columns: 2fr 1fr 2fr;
}
.filters-items > .component-dropDownList {
    z-index: 99999999;
    background-color: white;
    padding: 1px;
    border-radius: 10px;
}
/* Part List styles */
.partlist-filter-autocomplete-items {
    max-width: 1200px;
    grid-template-columns: 2fr 2fr;
    @apply grid px-5 py-2 gap-x-4 gap-y-2;
}

@media screen and (max-width: 1490px) {
    .filters-items {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 1350px) {
    .filters-items {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 1100px) {
    .filters-items {
        grid-template-columns: 1fr;
    }
}

table.chart.popover {
    padding: 5px;
    margin: 5px;
}
.chart.popover tr {
    padding: 5px;
    margin: 5px;
}

.chart.popover tbody td {
    border: solid 1px gray;
    border-collapse: separate;
    margin: 5px;
    text-align: center;
}
.chart.popover td.highlight {
    border: solid 2px red;
    background-color: rgba(255,0,0,0.2);
}

.chart.popover th .container {
    position: relative;
}
.chart.popover th .legend {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 16px;
    left: 0;
}
.chart.popover th .legend.subject {
    background-color: red;
}
.chart.popover th .legend.cross {
    background-color: black;
}
.chart.popover th .pn {
    margin-left: 16px;
}
.chart.popover th .manuf {
    margin-left: 16px;
    font-weight: normal;
    font-size: 80%;
    text-align: center;
}

.filters .component-dropDownList-select,
.filters .component-checboxDropdownList-input {
    @apply w-full ;
}

.channel-manager-toggles {
    @apply flex flex-row items-center my-8;
}

.channel-manager-table {
    @apply relative;
}

:where(.channel-manager-table) table {
    @apply rounded-[10px] overflow-hidden w-full shadow-lg;
}


:where(.channel-manager-table.with-crosses) th:nth-child(n+6) {
    @apply bg-brand-primary-400; 
}

:where(.channel-manager-table.with-alerts) th {
    background-color: #03080e;
}
:where(.channel-manager-table.with-alerts) th:nth-child(n+5) {
    background-color: inherit;
}

:where(.channel-manager-table.with-crosses.with-alerts) th {
    background-color: #03080e;
}
:where(.channel-manager-table.with-crosses.with-alerts) th:nth-child(n+5) {
    background-color: inherit;
}
:where(.channel-manager-table.with-crosses.with-alerts) th:nth-child(n+10) {
    @apply bg-brand-primary-400; 
}

:where(.channel-manager-table) th {
    @apply top-0;
}

:where(.channel-manager-table) td:has(.part-cell) {
    @apply whitespace-nowrap;
}

.highlight {
    @apply bg-red-300
  }

  .row-highlight {
    @apply bg-red-300
  }


.part-list-table {
    @apply relative;
}

:where(.part-list-table) table {
    @apply relative rounded-[10px] overflow-hidden w-full shadow-lg;
}

:where(.part-list-table) th {
    @apply top-0;
}

:where(.part-list-table) td:has(.part-cell) {
    @apply whitespace-nowrap;
}


:where(.part-lists-table) table {
    @apply relative rounded-[10px] overflow-hidden w-full shadow-lg;
}

:where(.part-lists-table) th {
    @apply top-0;
}

.part-lists-table table tr
{
    @apply border-0;
}

.part-lists-table table td {
    @apply border-0;
    @apply border-b-2;

}

[data-tooltip]::after {
    content: attr(data-tooltip);
    filter: drop-shadow(0 0 10px rgba(150,150,150,0.3));
    bottom: calc(100% + 0.5rem);
    @apply text-sm italic font-medium text-black absolute bg-white max-w-[220px] transition-all duration-300 ease-in-out p-2.5 -left-4 rounded-lg invisible opacity-0 pointer-events-none translate-y-4;
}

[data-tooltip]:hover::after {
    @apply visible opacity-100 translate-y-0;
}

/* Style scrollbars */
* {
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;

    background: theme(colors.brand-primary-50);
}

::-webkit-scrollbar-thumb {
    background: theme(colors.dark-blueish);
    border-radius: 6px;
}


::-webkit-scrollbar-corner {
    background: #ddd;
}


.modal-control {
    position: fixed;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.55);
    overflow-x: auto;
    inset: 0;
}

.modal-control .modal {
    @apply fixed;
    overflow-y: auto;
    max-height: 100%;
}


.domain-partlist.partlist-upload-modal-control .open-button {
    @apply flex flex-row items-center ml-5 cursor-pointer text-blueish hover:opacity-80;
}
.open-button {
    @apply flex flex-row items-center ml-5 cursor-pointer text-blueish hover:opacity-80;
}
.domain.partlist .domain-partlist.partlist-upload-modal-control .open-button {
    @apply absolute top-[118px] right-[42px];
}

.domain.channel-manager .domain-partlist.partlist-upload-modal-control .open-button {
    @apply absolute top-[313px] z-[11];
}


.domain.inventory .details-table-anchor {
    @apply relative top-[-80px];
}

.domain.inventory .details-table-container {
    @apply min-h-[800px];
}


.cross-ref-modal-container > .modal-control > .modal-children-container {
    width: 550px;
}

.config-edit-modal > .modal-control > .modal-children-container {
    max-height: 90%;
    overflow-y: scroll;
}       

.user-edit-modal > .modal-control > .modal-children-container{
}

.customer-edit-modal> .modal-control > .modal-children-container {
}

.customer-edit-modal, .component-dropDownList-select {
    @apply w-full
}

.tringle-container {
    position: relative;
    width: 100%; /* Boyutları kendi ihtiyacınıza göre ayarlayabilirsiniz */
    height: 100%;
}

.left-tringle {
    position: absolute;
    width: 100%;
    height: 0;
    right: 0;
    z-index: -10;
    opacity: 0.7;
    border-right: 14vw solid #D1DDE4;
    border-top: 6.5rem solid transparent;
    border-bottom: 6.5rem solid transparent;
}
.right-tringle {
    position: absolute;
    width: 100%;
    height: 0;
    left:0;
    z-index: -10;
    border-left: 14vw solid white;
    border-top: 6.5rem solid transparent;
    border-bottom: 6.5rem solid transparent;
}

@media (max-width: 430px) {
    .left-tringle {
        position: absolute;
        width: 100%;
        height: 0;
        right: 0;
        z-index: -10;
        opacity: 0.7;
        border-right: 42vw solid #D1DDE4;
        border-top: 6.5rem solid transparent;
        border-bottom: 6.5rem solid transparent;
    }
    .right-tringle {
        position: absolute;
        width: 100%;
        height: 0;
        left:0;
        overflow: visible;
        z-index: -10;
        border-left: 42vw solid white;
        border-top: 6.5rem solid transparent;
        border-bottom: 6.5rem solid transparent;
    }
  }
  @media (min-width: 430px) and (max-width: 768px) {
    .left-tringle {
        position: absolute;
        width: 100%;
        height: 0;
        right: 0;
        z-index: -10;
        opacity: 0.7;
        border-right: 45vw solid #D1DDE4;
        border-top: 6.5rem solid transparent;
        border-bottom: 6.5rem solid transparent;
    }
    .right-tringle {
        position: absolute;
        width: 100%;
        height: 0;
        left:0;
        overflow: visible;
        z-index: -10;
        border-left: 45vw solid white;
        border-top: 6.5rem solid transparent;
        border-bottom: 6.5rem solid transparent;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .left-tringle {
        position: absolute;
        width: 100%;
        height: 0;
        right: 0;
        z-index: -10;
        opacity: 0.7;
        border-right: 50vw solid #D1DDE4;
        border-top: 6.5rem solid transparent;
        border-bottom: 6.5rem solid transparent;
    }
    .right-tringle {
        position: absolute;
        width: 100%;
        height: 0;
        left:0;
        overflow: visible;
        z-index: -10;
        border-left: 50vw solid white;
        border-top: 6.5rem solid transparent;
        border-bottom: 6.5rem solid transparent;
    }
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    .left-tringle {
        position: absolute;
        width: 100%;
        height: 0;
        right: 0;
        z-index: -10;
        opacity: 0.7;
        border-right: 55vw solid #D1DDE4;
        border-top: 6.5rem solid transparent;
        border-bottom: 6.5rem solid transparent;
    }
    .right-tringle {
        position: absolute;
        width: 100%;
        height: 0;
        left:0;
        overflow: visible;
        z-index: -10;
        border-left: 55vw solid white;
        border-top: 6.5rem solid transparent;
        border-bottom: 6.5rem solid transparent;
    }
  }


.tooltip {
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    border-radius: 8px;
    padding: 5px;
    position: absolute;
    min-width: max-content;
    box-shadow: 1px 1px 20px 0px rgba(0,0,0,0.3);
    z-index: 9999;
}
.tooltip-left {
    left: 0;
}
.tooltip-right {
    right: 0;
}
.tooltip-title {
    font-weight: 800;
}

.overlay {
    @apply absolute flex flex-col items-center justify-center right-0 top-[80px] bottom-0 w-full  rounded-lg z-[99999];
    
    background: rgba(255,255,255,0.7);
}

.select-partlist-prompt {
    @apply flex flex-col items-center justify-center right-0 w-full h-[100px] ;
    
}
@keyframes slideFromLeft {
    0% {
        transform: translateX(-500px);
    }

    100% {
        transform: translateX(0);
    }
}
@keyframes slideToLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-500px);
    }
}

@keyframes slideFromRight {
    0% {
        transform: translateX(500px);
    }

    100% {
        transform: translateX(0);
    }
}
@keyframes slideToRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-500px);
    }
}
.alert-types-container {
    transition: all 0.3s ease-in-out;
    transition-delay: 0.3s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}
.slide-from-left {
    transition: all;
    animation: slideFromLeft 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

.slide-to-left {
    transition: all;
    animation: slideToLeft 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards
}

.slide-from-right {
    transition: all;
    animation: slideFromRight 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
}
/* Style part-compare */
.custom-shadow-none:focus{
    box-shadow: none;
}
#enter-value ~ .custom-percentage{
    font-size: 0;
    position: relative;
}
#enter-value ~ .custom-percentage:before{
    content: '%';
    position: absolute;
    font-size: 20px;
}

.alert-modal-popup-info-container .info-popup .info-popup-content {
    @apply w-72;
}

.domain.inventory .control-switch .unchecked,
.alert-manager-header-filter .control-switch .unchecked {
    @apply bg-[#0F2033];
}

.domain.alert-manager .alert-list-item .follower-container {
    @apply mb-[12px];
}

.manuf-dropdown {
    @apply w-full;
}
.manuf-dropdown label{
@apply text-[#B91C1C]
}

.bom-upload table tr,
.bom-upload table th {
    @apply border-0;
}

.bom-upload table td {
    @apply border-0;
    @apply border-b-2;
}

.domain.compliance-insights .checkbox-dropdown-list-2 .checkbox-list {
    @apply w-[500px];
}

.domain.compliance-insights.part-materials .title.part-number {
    @apply text-blueish;
}

.domain.compliance-insights .sortable-table .tablescrollBar  {
    @apply rounded-xl shadow-lg;
}

.domain.compliance-insights .chart-container {
    @apply bg-white px-4 py-6 mt-[48px] rounded-xl;
}


.domain.compliance-insights .card {
    @apply bg-white px-4 py-6 rounded-xl m-4 cursor-pointer drop-shadow-lg float-left;
}
.domain.compliance-insights .card.selected {
    @apply bg-light-blueish;
}
.domain.compliance-insights .card .score-card-count {
    @apply font-bold text-xl text-blueish;
}
							
.component-combobox {
    @apply relative;
}

.component-combobox .option-list {
    @apply absolute cursor-pointer text-blueish pl-2 bg-white h-[200px];
    
    overflow-y: scroll;
}

.manufacturer-combobox {

}

.card-container {
    @apply flex flex-col justify-center w-fit rounded-md;
}

.card-compliance {
   @apply bg-white px-2 py-1 text-center h-fit whitespace-nowrap rounded-xl m-1 my-2 cursor-pointer border border-[#6FACBD] float-left text-xs font-medium
}

.card-compliance.selected {
    @apply bg-light-blueish text-white;
}

.card-container.notPreFilter {
    @apply flex flex-col mt-2
}